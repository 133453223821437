import { ChangeDetectorRef, Component, NgZone, ElementRef, Renderer2 } from '@angular/core';
import { ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { BusyRouterService, ComponentBase, ComponentFeatures, DoInit, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { OpenIdService } from '@ngxhq/security';
import { MenuItem, PrimeNGConfig } from 'primeng/api';
import { CALENDAR_FR } from './utils/calendar-fr.locale';

// Change Role user only in DEV environment
import { SecurityService } from './services/security.service';
import { InfoService } from './modules/grievances/services/info.service';
import { AppInfo } from './models/app-info.interface';
const pjson = require('../../package.json');
//------------------------------------------------------------


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class AppComponent extends ComponentBase implements DoInit {
    // Change Role user only in DEV environment
    public appInfo: AppInfo = {
        environment: pjson.environment,
        date: pjson.date,
        version: pjson.version,
        roles: ''
    };
    //---------------------------------------

    private initNavigation = true;
    public showMenu: boolean = false;
    layout = 'shell';
    isMainMenuExpanded = true;
    autoLogoutIn: number | undefined;
    public breadcrumbs: MenuItem[] = [];

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private router: Router,
        private busyRouterService: BusyRouterService,
        private openIdService: OpenIdService,
        private zone: NgZone,
        private _el: ElementRef,
        private _renderer2: Renderer2,
        private _primeNGConfig: PrimeNGConfig,
        private _infoApp: InfoService,
        // Change Role user only in DEV environment
        private _securityService: SecurityService
        //-----------------------------------------
    ) {
        super();
    }

    doInit() {
        this.activateApplicationAutoLayout();
        this.activateNavigationBusyState();
        this.activateMainMenuAutoExpansion();
        this.listenToSessionTimeout();
        this._primeNGConfig.setTranslation(CALENDAR_FR);
    }

    private activateApplicationAutoLayout() {
        this.router.events
            .pipe(
                filter((event) => event instanceof ActivationEnd || event instanceof NavigationEnd),
                tap((event) => {
                    if (event instanceof NavigationEnd) {
                        this._loadBreadcrumb(event.urlAfterRedirects);
                        this._pageLayout(event);
                    } else {
                        this.setComponentLayout(event as ActivationEnd);
                    }
                }),
                takeUntil(this.destroyed)
            )
            .subscribe();
    }

    // TO-DO SR : REFACTORE THIS CODE
    private _pageLayout(event: NavigationEnd): void {
        const urlList: string[] = event['urlAfterRedirects'].split('/');
        const pos: number = urlList.length - 1;
        let url: string = urlList[pos];

        // Change Role user only in DEV || QA environment
        const urlSearchParams: URLSearchParams = new URLSearchParams(window.location.search);
        const role: string | null = urlSearchParams.get('role');

        if (role && (this.appInfo?.environment === 'dev' || this.appInfo?.environment === 'qa')) {
            this._securityService.setRoles([role]);
            url = urlList[pos].split('?')[0];
        }
        //-------------------------------------------

        switch (url) {
            case 'accueil':
            case 'pilotage':
            case 'dashboard':
                this.showMenu = false;
                break;
            default:
                this.showMenu = true;
                this._renderer2.removeClass(this._el.nativeElement.ownerDocument.body, 'fullscreen-background');
                break;
        }
    }

    private setComponentLayout(event: ActivationEnd): void {
        const newLayout = event.snapshot.data &&
            event.snapshot.data.layout ?
            event.snapshot.data.layout : 'shell';

        if (newLayout !== this.layout) {
            this.layout = newLayout;
            this.changeDetectorRef.detectChanges();
        }
    }

    private activateNavigationBusyState() {
        this.busyRouterService.showBusyWhenNavigating()
            .pipe(takeUntil(this.destroyed))
            .subscribe();
    }

    private activateMainMenuAutoExpansion() {
        this.router.events
            .pipe(
                tap(async (event) => {
                    if (event instanceof NavigationStart) {
                        this.detectInitialNavigation();
                    } else if (event instanceof NavigationEnd) {
                        await this.handleMainMenuExpansion();
                    }
                }),
                takeUntil(this.destroyed)
            )
            .subscribe();
    }

    private detectInitialNavigation(): void {
        this.initNavigation = !this.router.navigated;
    }

    // TO-DO SR : REFACTORE THIS CODE
    private handleMainMenuExpansion(): void {
        const isMobile = document.documentElement.clientWidth <= 480;

        if (isMobile) {
            this.isMainMenuExpanded = false;
        } else if (this.initNavigation) {
            this.isMainMenuExpanded = true;
        }

        this.openIdService.status.pipe(
            tap(async (e: any) => {
                if (e.isAuthenticated && this.router.url !== '/accueil') {
                    await this._infoApp.getInfoApp();
                }
            }),
            takeUntil(this.destroyed)
        ).subscribe(() => { }, (error: any) => console.log(error));
    }

    private listenToSessionTimeout() {
        this.openIdService.idleTimeoutRemainingSeconds
            .subscribe((x: number | undefined) => {
                this.zone.run(() => {
                    this.autoLogoutIn = x;
                });
            }, (err: any) => {
                console.error('idleTimeoutRemainingSeconds failed', err);
                this.zone.run(() => {
                    this.autoLogoutIn = undefined;
                });
            });
    }

    /**
     * @param  {string} urlAfterRedirects
     * @returns void
    */
    // TO-DO SR : REFACTORE THIS CODE
    private _loadBreadcrumb(urlAfterRedirects: string): void {
        const path: Array<string> = urlAfterRedirects.split('/');
        this.breadcrumbs = [];

        path.forEach((element: string) => {
            let breadcrumb: MenuItem;

            switch (element) {
                case 'griefs':
                    breadcrumb = { label: 'Griefs' };
                    break;
                case 'saisir-un-grief':
                    breadcrumb = { label: 'Saisir un grief' };
                    break;
                case 'visualiser-un-grief':
                    breadcrumb = { label: 'Visualiser un grief' };
                    break;
                case 'modifier-un-grief':
                    breadcrumb = { label: 'Modifier un grief' };
                    break;
                case 'dupliquer-un-grief':
                    breadcrumb = { label: 'Dupliquer un grief ' };
                    break;
                case 'liste-des-griefs':
                    breadcrumb = { label: 'Liste des griefs' };
                    break;
                case 'griefs-actifs':
                    breadcrumb = { label: 'Griefs actifs' };
                    break;
                case 'prochainement-en-arbitrage':
                    breadcrumb = { label: 'Prochainement en arbitrage' };
                    break;
                case 'mes-griefs':
                    breadcrumb = { label: 'Mes griefs' };
                    break;
                case 'tous-les-griefs':
                    breadcrumb = { label: 'Tous les griefs' };
                    break;
                case 'nouveaux-griefs':
                    breadcrumb = { label: 'Nouveaux griefs' };
                    break;
                case 'griefs-de-mon-mes-unites':
                    breadcrumb = { label: 'Griefs de mon/mes unites' };
                    break;
                case 'sous-motifs':
                    breadcrumb = { label: 'Sous motifs' };
                    break;
                case 'recherche-avancee':
                    breadcrumb = { label: 'Recherche avancée' };
                    break;
                case 'procureurs-syndicaux':
                    breadcrumb = { label: 'Procureurs syndicaux' };
                    break;
                case 'procureurs-patronaux':
                    breadcrumb = { label: 'Procureurs patronaux' };
                    break;
                case 'responsable-rh':
                    breadcrumb = { label: 'Responsable RH' };
                    break;
                case 'type-pieces-jointes':
                    breadcrumb = { label: 'Type de pièces jointes' };
                    break;
                case 'parametres-application':
                    breadcrumb = { label: 'Paramètres d\'application' };
                    break;
                case 'non-existing-structural-unit':
                    breadcrumb = { label: 'Gestion des unités structurelles non existante' };
                    break;
                case 'document-management':
                    breadcrumb = { label: 'Gestion des documents' };
                    break;
                case 'hr-management':
                    breadcrumb = { label: 'Gestion du responsable RH' };
                    break;
                case '':
                    breadcrumb = { icon: 'pi pi-home', routerLink: 'accueil' };
                    break;
                default:
                    breadcrumb = { label: element.charAt(0).toUpperCase() + element.slice(1) };
                    break;
            }

            this.breadcrumbs.push(breadcrumb);

            (this.breadcrumbs[3] && this.breadcrumbs[3].label!.split('?')[1] !== undefined) ?
                this.breadcrumbs[3].label! = this.breadcrumbs[3].label!.split('?')[0] : null;
        });

    }

}
