import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PilotageControllerService } from '../core/api/client/services';

@Injectable({
    providedIn: 'root'
})
export class PilotageMainService {
    public dataTypePieceJointes$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
    public dataResponsableRH$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    constructor(
        private _pilotageControllerService: PilotageControllerService,
    ) {
        this._pilotageControllerService.getAllResponsableRHsUsingGET().subscribe((data: any) => {
            this.dataResponsableRH$.next(data);
        }, (err: any) => console.log(err));
    }
}
